/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  /* padding: 30px !important; */
/*}

button i {
  margin-right: 5px;
}

.ant-notification {
  z-index: 10000 !important;
}

.centered-cell {
  text-align: center;
}*/

.ant-page-header {
  padding-left: 0px !important;
}

.header {
  background: white;
  display: flex;
  align-items: center;
}

.card-footer {
  border-top-style: dashed !important;
}

.custom-border-error .ant-upload-list-picture-card-container {
  border: solid red 1px !important;
}