/* html {
  background: white;
} */
/* 
.wrapper {
  height: 'auto';
  min-height: 100%;
  /* margin-left: 50px; */
/* } */

/* .content {
  height: auto;
  min-height: 87vh;
  background: #ECEFF5; */
/* } */

.action-cell {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 60px;
}

.transparent {
  color: transparent !important;
  content: '';
}

.ant-table-cell.text-align-left,
.ant-table-cell.text-align-left div {
  text-align: left;
}

.ant-table-cell.text-align-center,
.ant-table-cell.text-align-center div,
th.ant-table-cell.title-text-align-center,
th.ant-table-cell.title-text-align-center div {
  text-align: center !important;
}

.ant-table-cell.text-align-right,
.ant-table-cell.text-align-right div,
.ant-table-cell.content-text-align-right,
.ant-table-cell.content-text-align-right div {
  text-align: right;
}

.clickable {
  cursor: pointer;
}